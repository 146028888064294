type NavigationState = {
  isOnTop: boolean;
};

export const useNavigationStore = defineStore("navigation", {
  state: () =>
    ref<NavigationState>({
      isOnTop: false,
    }),
});
